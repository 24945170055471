export { matchers } from './client-matchers.js';

export const components = [
	() => import("../../src/routes/__layout.svelte"),
	() => import("../runtime/components/error.svelte"),
	() => import("../../src/routes/order/[orderId]/__layout.svelte"),
	() => import("../../src/routes/auth/fortnox.svelte"),
	() => import("../../src/routes/auth/login.svelte"),
	() => import("../../src/routes/auth/update-token.svelte"),
	() => import("../../src/routes/car/[id]/index.svelte"),
	() => import("../../src/routes/car/new/index.svelte"),
	() => import("../../src/routes/finance/index.svelte"),
	() => import("../../src/routes/index.svelte"),
	() => import("../../src/routes/job/[id]/add-location/index.svelte"),
	() => import("../../src/routes/job/[id]/assign/index.svelte"),
	() => import("../../src/routes/job/[id]/checkout/index.svelte"),
	() => import("../../src/routes/job/[id]/checkout/summary/index.svelte"),
	() => import("../../src/routes/job/[id]/extra-charge/index.svelte"),
	() => import("../../src/routes/job/[id]/help/index.svelte"),
	() => import("../../src/routes/job/[id]/index.svelte"),
	() => import("../../src/routes/job/[id]/report/index.svelte"),
	() => import("../../src/routes/job/schedule-[workerId]/activity/cleaning/index.svelte"),
	() => import("../../src/routes/job/schedule-[workerId]/activity/index.svelte"),
	() => import("../../src/routes/job/schedule-[workerId]/activity/swat/index.svelte"),
	() => import("../../src/routes/job/schedule-[workerId]/done/index.svelte"),
	() => import("../../src/routes/job/schedule-[workerId]/exchange/index.svelte"),
	() => import("../../src/routes/job/schedule-[workerId]/feedback/index.svelte"),
	() => import("../../src/routes/job/schedule-[workerId]/index.svelte"),
	() => import("../../src/routes/job/schedule-[workerId]/invite/index.svelte"),
	() => import("../../src/routes/job/schedule-[workerId]/market/[jobId]/index.svelte"),
	() => import("../../src/routes/job/schedule-[workerId]/market/index.svelte"),
	() => import("../../src/routes/job/storage-[storageId]/index.svelte"),
	() => import("../../src/routes/order/[orderId]/action-assign-invoice/index.svelte"),
	() => import("../../src/routes/order/[orderId]/action-update-order/index.svelte"),
	() => import("../../src/routes/order/[orderId]/action-update-status/index.svelte"),
	() => import("../../src/routes/order/[orderId]/create-delivery/index.svelte"),
	() => import("../../src/routes/order/[orderId]/index.svelte"),
	() => import("../../src/routes/order/[orderId]/info.svelte"),
	() => import("../../src/routes/order/[orderId]/invoice/[invoiceId]/assign.svelte"),
	() => import("../../src/routes/order/[orderId]/invoice/[invoiceId]/bookkeep.svelte"),
	() => import("../../src/routes/order/[orderId]/invoice/[invoiceId]/delete.svelte"),
	() => import("../../src/routes/order/[orderId]/invoice/[invoiceId]/index.svelte"),
	() => import("../../src/routes/order/[orderId]/invoice/new.svelte"),
	() => import("../../src/routes/order/[orderId]/job/[jobId]-assign-location.svelte"),
	() => import("../../src/routes/order/[orderId]/job/[jobId]-assign-transaction.svelte"),
	() => import("../../src/routes/order/[orderId]/job/[jobId]-delete.svelte"),
	() => import("../../src/routes/order/[orderId]/job/[jobId]-duplicate.svelte"),
	() => import("../../src/routes/order/[orderId]/job/[jobId].svelte"),
	() => import("../../src/routes/order/[orderId]/job/new.svelte"),
	() => import("../../src/routes/order/[orderId]/location/[locationId]-delete.svelte"),
	() => import("../../src/routes/order/[orderId]/location/[locationId].svelte"),
	() => import("../../src/routes/order/[orderId]/location/new.svelte"),
	() => import("../../src/routes/order/[orderId]/overview.svelte"),
	() => import("../../src/routes/order/[orderId]/storage/[storageId]-assign-transaction.svelte"),
	() => import("../../src/routes/order/[orderId]/storage/[storageId]-delete.svelte"),
	() => import("../../src/routes/order/[orderId]/storage/[storageId].svelte"),
	() => import("../../src/routes/order/[orderId]/storage/new.svelte"),
	() => import("../../src/routes/order/[orderId]/transaction/[transactionId]-delete.svelte"),
	() => import("../../src/routes/order/[orderId]/transaction/[transactionId].svelte"),
	() => import("../../src/routes/order/[orderId]/transaction/new.svelte"),
	() => import("../../src/routes/order/new/index.svelte"),
	() => import("../../src/routes/planning/index.svelte"),
	() => import("../../src/routes/planning/map/index.svelte"),
	() => import("../../src/routes/planning/resources/optimiser/index.svelte"),
	() => import("../../src/routes/report-time/[id].svelte"),
	() => import("../../src/routes/report-time/new/index.svelte"),
	() => import("../../src/routes/resources/index.svelte"),
	() => import("../../src/routes/resources/presets/index.svelte"),
	() => import("../../src/routes/rut/index.svelte"),
	() => import("../../src/routes/worker/[id]/index.svelte"),
	() => import("../../src/routes/worker/new/index.svelte")
];

export const dictionary = {
	"": [[0, 9], [1]],
	"finance": [[0, 8], [1], 1],
	"planning": [[0, 58], [1], 1],
	"resources": [[0, 63], [1]],
	"rut": [[0, 65], [1], 1],
	"auth/fortnox": [[0, 3], [1]],
	"auth/login": [[0, 4], [1], 1],
	"auth/update-token": [[0, 5], [1]],
	"car/new": [[0, 7], [1], 1],
	"order/new": [[0, 57], [1], 1],
	"planning/map": [[0, 59], [1], 1],
	"report-time/new": [[0, 62], [1], 1],
	"resources/presets": [[0, 64], [1], 1],
	"worker/new": [[0, 67], [1], 1],
	"planning/resources/optimiser": [[0, 60], [1], 1],
	"job/schedule-[workerId]": [[0, 24], [1], 1],
	"job/storage-[storageId]": [[0, 28], [1], 1],
	"job/schedule-[workerId]/activity": [[0, 19], [1]],
	"job/schedule-[workerId]/done": [[0, 21], [1], 1],
	"job/schedule-[workerId]/exchange": [[0, 22], [1], 1],
	"job/schedule-[workerId]/feedback": [[0, 23], [1], 1],
	"job/schedule-[workerId]/invite": [[0, 25], [1], 1],
	"job/schedule-[workerId]/market": [[0, 27], [1], 1],
	"job/schedule-[workerId]/activity/cleaning": [[0, 18], [1], 1],
	"job/schedule-[workerId]/activity/swat": [[0, 20], [1], 1],
	"job/schedule-[workerId]/market/[jobId]": [[0, 26], [1], 1],
	"car/[id]": [[0, 6], [1], 1],
	"job/[id]": [[0, 16], [1], 1],
	"order/[orderId]": [[0, 2, 33], [1]],
	"report-time/[id]": [[0, 61], [1], 1],
	"worker/[id]": [[0, 66], [1], 1],
	"job/[id]/add-location": [[0, 10], [1], 1],
	"job/[id]/assign": [[0, 11], [1], 1],
	"job/[id]/checkout": [[0, 12], [1], 1],
	"job/[id]/extra-charge": [[0, 14], [1], 1],
	"job/[id]/help": [[0, 15], [1], 1],
	"job/[id]/report": [[0, 17], [1], 1],
	"order/[orderId]/action-assign-invoice": [[0, 2, 29], [1], 1],
	"order/[orderId]/action-update-order": [[0, 2, 30], [1], 1],
	"order/[orderId]/action-update-status": [[0, 2, 31], [1]],
	"order/[orderId]/create-delivery": [[0, 2, 32], [1], 1],
	"order/[orderId]/info": [[0, 2, 34], [1], 1],
	"order/[orderId]/overview": [[0, 2, 49], [1], 1],
	"job/[id]/checkout/summary": [[0, 13], [1], 1],
	"order/[orderId]/invoice/new": [[0, 2, 39], [1], 1],
	"order/[orderId]/job/new": [[0, 2, 45], [1], 1],
	"order/[orderId]/location/new": [[0, 2, 48], [1], 1],
	"order/[orderId]/storage/new": [[0, 2, 53], [1], 1],
	"order/[orderId]/transaction/new": [[0, 2, 56], [1], 1],
	"order/[orderId]/job/[jobId]-assign-location": [[0, 2, 40], [1], 1],
	"order/[orderId]/job/[jobId]-assign-transaction": [[0, 2, 41], [1], 1],
	"order/[orderId]/job/[jobId]-delete": [[0, 2, 42], [1], 1],
	"order/[orderId]/job/[jobId]-duplicate": [[0, 2, 43], [1], 1],
	"order/[orderId]/location/[locationId]-delete": [[0, 2, 46], [1], 1],
	"order/[orderId]/storage/[storageId]-assign-transaction": [[0, 2, 50], [1], 1],
	"order/[orderId]/storage/[storageId]-delete": [[0, 2, 51], [1], 1],
	"order/[orderId]/transaction/[transactionId]-delete": [[0, 2, 54], [1], 1],
	"order/[orderId]/invoice/[invoiceId]": [[0, 2, 38], [1], 1],
	"order/[orderId]/job/[jobId]": [[0, 2, 44], [1], 1],
	"order/[orderId]/location/[locationId]": [[0, 2, 47], [1], 1],
	"order/[orderId]/storage/[storageId]": [[0, 2, 52], [1], 1],
	"order/[orderId]/transaction/[transactionId]": [[0, 2, 55], [1], 1],
	"order/[orderId]/invoice/[invoiceId]/assign": [[0, 2, 35], [1], 1],
	"order/[orderId]/invoice/[invoiceId]/bookkeep": [[0, 2, 36], [1], 1],
	"order/[orderId]/invoice/[invoiceId]/delete": [[0, 2, 37], [1], 1]
};